import React from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import UIButton from './UIButton';
import UIField from './UIField';
import UICheckbox from './UICheckbox';
import UIRadioGroup from './UIRadioGroup';
import PortalsUI from './PortalsUI';
import UIStats from './UIStats';
import { IconButton } from '@material-ui/core';
import ReactToPrint from 'react-to-print';

export default class UIScope extends React.Component {
  state = {};
  _refs = {};

  constructor(props) {
    super(props);

    let changedValues = {};
    const { data } = this.props;
    if (!data || !data.id) return;
    (data.value || []).forEach(
      item => item.id && item.__originalValue && (changedValues[item.id] = item)
    );
    if (Object.keys(changedValues).length) this.state = { changedValues };
  }

  componentWillUnmount() {
    this._isUnmounted = true;
  }

  _handleClick = async item => {
    const { data = {}, onButtonClick } = this.props;

    if (data.value) {
      for (const dataItem of data.value) {
        if (!dataItem.id) continue;
        const ref = this._refs[dataItem.id];
        if (!ref || !ref.validate) continue;
        if (!ref.validate()) {
          ref.markInvalid && ref.markInvalid();
          return;
        }
      }
    }

    onButtonClick && onButtonClick(item);
  };

  _handleBackClick = () => {
    const { onBackClick } = this.props;
    onBackClick && onBackClick();
  };

  _handleFieldChange = (item, value) => {
    const { data } = this.props;
    const { changedValues = {} } = this.state;
    if (!data || !data.id) return;
    if (
      item.__originalValue !== undefined &&
      value.toString() === item.__originalValue.toString()
    ) {
      item.value = value;
      delete changedValues[item.id];
      delete item.__originalValue;
      if (!Object.keys(changedValues).length)
        return this.setState({ changedValues: undefined });
      this.setState({ changedValues: { ...changedValues } });
    } else if (!changedValues[item.id] || item.__originalValue === undefined) {
      item.__originalValue = item.value;
      item.value = value;
      this.setState({ changedValues: { ...changedValues, [item.id]: item } });
    }
  };

  _handleCancelClick = async () => {
    const { data } = this.props;
    const { changedValues = {} } = this.state;
    if (!data || !data.id) return;
    Object.keys(changedValues).forEach(key => {
      const item = changedValues[key];
      item.value = item.__originalValue;
      item.__key = (Date.now() + Math.random()).toString();
      delete item.__originalValue;
    });
    this.setState({ changedValues: undefined });
  };

  _handleSaveClick = async call => {
    const { data, onChange } = this.props;
    let { changedValues = {} } = this.state;
    changedValues = Object.keys(changedValues).reduce((res, key) => {
      res[key] = changedValues[key].value;
      delete changedValues[key].__originalValue;
      return res;
    }, {});
    if (!data || !data.id) return;
    const scope = {};
    data.value.forEach(
      ({ id, value }) => id && value !== undefined && (scope[id] = value)
    );
    if (onChange) {
      try {
        await onChange(call, {
          [data.id]: { _id: scope._id, ...changedValues },
        });
        if (this._isUnmounted) return;
        this.setState({ changedValues: undefined });
      } catch (e) {
        console.log('UIScope._handleSaveClick, Line 392: e >', e);
      }
    }
  };

  render() {
    const { changedValues } = this.state;
    const { classes, backButtonIcon, disabled, title, env } = this.props;
    let { data } = this.props;
    let saveButtonComponent, cancelButtonComponent;

    let { value = data, props: valueProps = {} } = data;

    if (!Array.isArray(value)) {
      value = [{ value: 'Malfomed UI', type: 'string' }];
    } else {
      const { props: { saveButton, cancelButton } = {} } = data;
      saveButtonComponent = changedValues && saveButton && (
        <UIButton
          classes={classes}
          {...saveButton.props}
          name={saveButton.name}
          onClick={() => this._handleSaveClick(saveButton.calls)}
        />
      );
      cancelButtonComponent = changedValues && cancelButton && (
        <UIButton
          classes={classes}
          {...cancelButton.props}
          name={cancelButton.name}
          onClick={this._handleCancelClick}
        />
      );
    }

    this.__id = Math.random().toString();

    return (
      <div
        className={classNames(classes.scope, { [classes.disabled]: disabled })}
      >
        <div>
          <div className={classes.buttonsContainer}>
            {title && (
              <Typography
                variant="subtitle1"
                gutterBottom={false}
                style={{ opacity: 0.5, marginBottom: 10 }}
              >
                {title}
              </Typography>
            )}
            {backButtonIcon && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 10,
                  alignItems: 'center',
                }}
              >
                {valueProps.customAction && (
                  <ReactToPrint
                    trigger={() => (
                      <IconButton>
                        <Icon>{valueProps.customAction.icon}</Icon>
                      </IconButton>
                    )}
                    content={() => this._contentRef}
                  />
                )}
                <Button
                  style={{ flex: 1 }}
                  size="large"
                  className={classes.button}
                  onClick={this._handleBackClick}
                >
                  <Typography variant="h6" gutterBottom={false}>
                    {data.name}
                  </Typography>
                  <Icon className={classes.rightIcon}>{backButtonIcon}</Icon>
                </Button>
              </div>
            )}
            {saveButtonComponent}
            {cancelButtonComponent}
          </div>
        </div>
        <div className={classes.buttonsContainerWrapper}>
          {data.description && (
            <Typography variant="body1" className={classes.description}>
              <Icon
                fontSize={'small'}
                style={{ marginRight: 4, marginBottom: -4 }}
              >
                info
              </Icon>
              {data.description}
            </Typography>
          )}
          <div
            ref={el => (this._contentRef = el)}
            className={classes.buttonsContainer}
          >
            {value &&
              value.map((item, index) => {
                const { type, props = {}, calls, reveals } = item;
                const key =
                  item.__key ||
                  item.id ||
                  item.name ||
                  (typeof item === 'string' ? item : index);
                switch (type) {
                  case 'any':
                  case 'function':
                  case 'button':
                    return (
                      <UIButton
                        key={key}
                        classes={classes}
                        {...props}
                        name={item.name}
                        disabled={
                          (!calls && !reveals) || props.disabled === true
                        }
                        onClick={() => this._handleClick(item, value)}
                      />
                    );
                  case 'email':
                  case 'password':
                  case 'number':
                  case 'string':
                    return (
                      <UIField
                        ref={item.id && (ref => (this._refs[item.id] = ref))}
                        key={key}
                        classes={classes}
                        data={item}
                        onChange={value => this._handleFieldChange(item, value)}
                      />
                    );
                  case 'boolean':
                    return (
                      <UICheckbox key={key} data={item} classes={classes} />
                    );
                  case 'oneOf':
                    return (
                      <UIRadioGroup key={key} data={item} classes={classes} />
                    );
                  case 'stats':
                    return <UIStats key={key} data={item} env={env} />;
                  default:
                    const {
                      [type]: CustomComponent,
                    } = PortalsUI.customComponents;
                    if (CustomComponent)
                      return <CustomComponent key={key} data={item} />;

                    return '- no type -';
                }
              })}
          </div>
        </div>
      </div>
    );
  }
}
