import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Routes from './routes';
import { NavLink } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
// import { Portals } from './portals'
// import PortalsView from './portals/ui/react/PortalsView'

// const { Portal, WebSocketChannel } = Portals

// const CHANNEL = new WebSocketChannel('ws://localhost:8080')

class App extends Component {
  state = {};

  constructor(props) {
    super(props);
    this._initPortal();
  }

  _initPortal = async () => {
    // const portal = await new Portal('frontend').setChannel(CHANNEL)
    // const env = await portal.connectTo('backend')
    // const interFace = portal.getInterface('backend')
    // this.setState({ env, interFace })
  };

  render() {
    // const { env, interFace } = this.state
    return (
      <Routes>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Meritodynamics.com</title>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"
          />
        </Helmet>
        {/*<header>*/}
        {/*  <NavLink to="/">Main page</NavLink>*/}
        {/*  <NavLink to="/admin">Admin</NavLink>*/}
        {/*  <NavLink to="/user">User</NavLink>*/}
        {/*</header>*/}
      </Routes>
    );
  }
}

export default App;
