import { useState, useCallback } from 'react';

const InitState = [];

export default function useSetColors(init = InitState) {
  const [colors, setColors] = useState(init);

  const addColors = useCallback(color => {
    setColors(prevState => {
      return prevState.concat(color);
    });
  });

  return [colors, addColors];
}
