import React from 'react';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';

export default class UIButton extends React.Component {
  render() {
    const {
      classes,
      name,
      size,
      variant,
      color,
      icon,
      onClick,
      attention,
      disabled,
    } = this.props;

    // console.log('UIButton.render, Line 10: this.props.props >', this.props);

    return (
      <Button
        disabled={disabled}
        size={size || 'large'}
        variant={variant || 'contained'}
        color={color || 'secondary'}
        style={disabled ? { pointerEvents: 'none' } : {}}
        className={classNames(classes.button, {
          [classes.buttonImportant]: attention,
        })}
        onClick={onClick}
      >
        {name}
        {icon !== '' && (
          <Icon className={classes.rightIcon}>{icon || 'arrow_forward'}</Icon>
        )}
      </Button>
    );
  }
}
