import UIRadioGroup from '../portals-ui/react/UIRadioGroup';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { capitalize } from '@material-ui/core/utils/helpers';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import React from 'react';

export default {
  notification: ({ data }) => (
    <Button
      variant={'outlined'}
      // disabled
      size={'small'}
      style={{
        textTransform: 'none',
        pointerEvents: 'none',
      }}
      color={'secondary'}
    >
      <Icon>warning</Icon>
      {data.value}
    </Button>
  ),

  userDashboardCard: ({ data }) => {
    const {
      value: {
        convertedGameStatus,
        user: { firstName, lastName, lepts, merits },
        game: { name: gameName, status: gameStatus },
        group: { name: groupName },
      },
    } = data;
    return (
      <Card style={{ padding: 5, margin: '3px 3px 10px 3px' }}>
        <CardContent>
          <Typography
            variant="h5"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Icon style={{ marginLeft: -3 }}>person</Icon>
            {`${capitalize(firstName)} ${capitalize(lastName)}`}
          </Typography>
          <Typography variant="subtitle1">
            <span style={{ fontFamily: 'meritodynamics-icons' }}>
              lept-circle-inverted
            </span>{' '}
            Лепты: {lepts}
          </Typography>
          <Typography variant="subtitle1">
            <span style={{ fontFamily: 'meritodynamics-icons' }}>
              merit-circle-inverted
            </span>{' '}
            Мериты: {merits}
          </Typography>
          <Divider variant="fullWidth" />
          <Typography variant="h6">Игра: {gameName}</Typography>
          <Typography variant="subtitle1">
            Статус: {convertedGameStatus}
          </Typography>
          <Divider variant="fullWidth" />
          <Typography variant="h6">Группа: {groupName}</Typography>
        </CardContent>
      </Card>
    );
  },

  infoString: ({ data }) => (
    <Paper style={{ padding: 5, margin: 3 }}>
      <Typography>
        {data.name}: {data.value}
      </Typography>
    </Paper>
  ),

  switch:
    // TODO: Add switch!
    ({ data }) => <div>switch</div>,

  stats: ({ data }) => <div>{JSON.stringify(data)}</div>,
};
