import { useState, useCallback } from 'react';

const initState = false;

export default function useToggleContainerLoaded(init = initState) {
  const [containerLoaded, setContainerLoaded] = useState(init);

  const toggleContainerLoaded = useCallback(() => {
    setContainerLoaded(prevState => !prevState);
  });

  return [containerLoaded, toggleContainerLoaded];
}
