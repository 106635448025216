import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import MainPage from '../containers/MainPage';
// import Admin from '../containers/Admin'
// import User from '../containers/User'

class Routes extends Component {
  render() {
    return (
      <Router>
        {this.props.children}
        <Route exact path="/*" component={MainPage} />
        {/*<Route exact path="/admin" component={Admin}/>*/}
        {/*<Route exact path="/user" component={User}/>*/}
      </Router>
    );
  }
}

export default Routes;
