import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import GroupStatsByMeritsContainer from './groupStatsByMerits';
import GroupStatsByLeptsContainer from './groupStatsByLepts';
import GameStatsByUnitContainer from './gameStatsByUnit';

const StatisticsContainer = ({ data, env }) => {
  const {
    value: {
      game: { _id: gameId },
      type,
      unit,
    },
  } = data;

  const [gameData, setGameData] = useState({});

  useEffect(() => {
    const { getGameDetails } = env;
    const gameDetails = async () => {
      setGameData(await getGameDetails(gameId));
    };
    gameDetails();
  }, [gameId]);

  const renderStatComponent = () => {
    switch (type) {
      case 'groupStatsByMerits':
        return <GroupStatsByMeritsContainer data={gameData} />;
      case 'groupStatsByLepts':
        return <GroupStatsByLeptsContainer data={gameData} />;
      case 'gameStatsByUnit':
        return <GameStatsByUnitContainer data={gameData} unit={unit} />;
      default:
        return <div>no component</div>;
    }
  };

  return (
    <div>{!_isEmpty(gameData) ? renderStatComponent() : 'Loading...'}</div>
  );
};

StatisticsContainer.propTypes = {
  data: PropTypes.object,
  env: PropTypes.object,
};

export default StatisticsContainer;
