import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import _isEmpty from 'lodash/isEmpty';
import RandomColor from 'randomcolor';

import Component from './component';
import useToggleNames from '../helpers/useToggleNames';
import useTogglePercents from '../helpers/useTogglePercents';
import useSetColors from '../helpers/useSetColors';

const GroupStatsByLeptsContainer = ({ data }) => {
  const [gameData, setGameData] = useState({});
  const [showNames, setShowNames] = useToggleNames(false);
  const [showPercents, setShowPercents] = useTogglePercents(false);
  const [COLORS, addColors] = useSetColors();

  useEffect(() => {
    prepareGameData(data);
  }, data);

  const prepareGameData = ({ game, groups }) => {
    const preparedGroups = groups.map(group => {
      let groupLepts = 0;
      addColors(
        RandomColor({
          luminosity: 'light',
          hue: 'random',
          seed: `${group.name}${group._id}${group.participants.length}`,
        })
      );
      group.participants.map(participant => {
        groupLepts += participant.lepts;
        return participant;
      });
      return {
        ...group,
        groupLepts,
      };
    });
    setGameData({
      game,
      groups: preparedGroups,
    });
  };

  if (_isEmpty(gameData)) return 'Loading...';
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={showNames}
            onChange={() => {
              setShowNames(prevState => !prevState);
            }}
          />
        }
        label="Показывать имена"
        style={{
          marginLeft: 15,
        }}
      />
      <FormControlLabel
        control={
          <Switch
            checked={showPercents}
            onChange={() => {
              setShowPercents(prevState => !prevState);
            }}
          />
        }
        label="Показывать процент от общего количества"
        style={{
          marginLeft: 15,
        }}
      />

      <Component
        data={gameData}
        showPercents={showPercents}
        showNames={showNames}
        colors={COLORS}
      />
    </>
  );
};

GroupStatsByLeptsContainer.propTypes = {
  data: PropTypes.object,
};

export default GroupStatsByLeptsContainer;
