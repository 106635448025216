import { useState, useCallback } from 'react';

const initState = false;

export default function useTogglePercents(init = initState) {
  const [showPercents, setShowPercents] = useState(init);

  const togglePercents = useCallback(() => {
    setShowPercents(prevState => !prevState);
  });

  return [showPercents, togglePercents];
}
