import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

export default class UIRadioGroup extends React.Component {
  _handleChange = ({ target: { value } }) => {
    console.log('UIRadioGroup._handleChange, Line 278: value >', value);
    const { data } = this.props;
    data.value = isNaN(Number(value)) ? value : Number(value);
    this.setState({});
  };
  render() {
    const { data = {}, classes } = this.props;
    const { id, name, props: { options = [], disabled = false } = {} } = data;
    const value = data.value !== undefined ? data.value : data.defaultValue;
    console.log('value: ', value);
    return (
      <FormControl
        component="fieldset"
        disabled={disabled}
        className={classes.radioGroupFormControl}
      >
        <FormLabel component="legend">{name}</FormLabel>
        <RadioGroup
          aria-label={name}
          name={name}
          // className={classes.group}
          value={value.toString()}
          onChange={this._handleChange}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={index.toString()}
              value={option._id || index.toString()}
              control={<Radio />}
              label={option.title || option}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}
