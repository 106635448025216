import React from 'react';
import TextField from '@material-ui/core/TextField';

import { Portals } from 'portals';
import { LeptIcon, MeritIcon } from '../../components/icons'

const { fixValue } = Portals;

export default class UIField extends React.Component {
  _inputRef;

  constructor(props) {
    super(props);

    const { value, defaultValue } = props.data || {};

    this.state = { value: value !== undefined ? value : defaultValue };
  }

  _handleChange = ({ target: { value } }) => {
    const { onChange } = this.props;
    this.setState({ value, invalid: undefined });
    onChange && onChange(value);
  };

  _handleBlur = ({ target: { value } }) => {
    const { data = {} } = this.props;
    value = fixValue(value, data);
    this.props.data.value = value;
    this.setState({ value });
  };

  validate = () => {
    if (
      this._inputRef &&
      this._inputRef.checkValidity &&
      !this._inputRef.checkValidity()
    )
      return false;

    return true;
  };

  markInvalid = () => {
    this.setState({ invalid: true });
  };

  render() {
    const { classes, data = {} } = this.props;
    const { invalid } = this.state;
    // console.log('UIField.render, Line 141: data >', data)
    const {
      id,
      type,
      // value,
      required,
      props: valueProps = {},
      __key,
      // defaultValue = typeof value !== 'object' ? value : undefined,
    } = data;

    let { error, multiline, rowsMax, isLept, isMerit, visible, ...restValueProps } = valueProps;

    if(visible === false && process.env.NODE_ENV !== 'production'){
      visible = true
      restValueProps.readOnly = true
      valueProps.disabled = true
    }

    if (!id) {
      restValueProps.readOnly = true;
      restValueProps.disabled = true;
    }

    const { value = '' } = this.state;

    let { name } = data
    const leptOrMeritIcon =
      isLept ? <LeptIcon/> : isMerit ? <MeritIcon/> : undefined

    if(leptOrMeritIcon)
      name = (
        <React.Fragment>
          {leptOrMeritIcon} {name}
        </React.Fragment>
      );

    return (
      <TextField
        inputRef={ref => (this._inputRef = ref)}
        key={__key}
        classes={
          visible === false ? { root: classes.invisible } : {}
        }
        label={name || id}
        type={type}
        className={classes.input}
        multiline={multiline}
        rowsMax={rowsMax}
        // defaultValue={defaultValue}
        value={value}
        onChange={this._handleChange}
        onBlur={this._handleBlur}
        // margin="normal"
        variant="outlined"
        required={required}
        inputProps={restValueProps}
        error={invalid || error}
      />
    );
  }
}
