import { useState, useCallback } from 'react';

const initState = false;

export default function useToggleNames(init = initState) {
  const [showNames, setShowNames] = useState(init);

  const toggleShowNames = useCallback(() => {
    setShowNames(prevState => !prevState);
  });

  return [showNames, toggleShowNames];
}
