import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default class UICheckbox extends React.Component {
  _handleChange = () => {
    const { data } = this.props;
    data.value = !(data.value !== undefined ? data.value : data.defaultValue);
    this.setState({});
  };

  render() {
    const { data, classes } = this.props;
    const checked = data.value !== undefined ? data.value : data.defaultValue;
    const { props: valueProps = {} } = data;

    return (
      <FormControlLabel
        disabled={valueProps.disabled}
        classes={{
          root:
            valueProps.visible === 'false'
              ? classes.invisible
              : classes.checkbox,
        }}
        labelPlacement="end"
        control={
          <Checkbox
            checked={checked}
            onChange={this._handleChange}
            value={data.id}
          />
        }
        label={data.name}
      />
    );
  }
}
