import React, { useState, useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Treemap } from 'react-vis';
import BigNumber from 'bignumber.js';
import 'react-vis/dist/style.css';
import '../styles/treemapStyles.css';
import { Typography } from '@material-ui/core';
import useToggleContainerLoaded from '../helpers/useToggleContainerLoaded';

const StatsComponent = ({ data, showNames, showPercents, colors }) => {
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState({});
  const [valuePercentage, setValuePercentage] = useState(0);
  const [containerLoaded, setContainerLoaded] = useToggleContainerLoaded(false);
  const statsContainerRef = useRef(null);

  const normalizedGroups = () => {
    const { groups } = data;
    const groupsData = groups.map((group, index) => {
      const color = colors[index];
      const participants = group.participants.map(participant => {
        return {
          ...participant,
          totalMerits: participant.merits,
          title: (
            <>
              {showPercents && (
                <Typography
                  className="info-text"
                  style={{
                    marginTop: 'auto',
                  }}
                >
                  {new BigNumber(participant.merits / (group.groupMerits / 100))
                    .toFixed(2)
                    .toString()}
                  %
                </Typography>
              )}
              {showNames && (
                <Typography
                  className="info-text"
                  style={{
                    marginTop: showPercents ? 5 : 'auto',
                  }}
                >
                  {participant.user.firstName} {participant.user.lastName}
                </Typography>
              )}
            </>
          ),
          className: 'test',
          color,
        };
      });
      return {
        ...group,
        children: participants,
        title: (
          <Typography
            style={{
              marginLeft: 5,
              color: '#000',
              fontSize: 14,
            }}
          >
            {group.name} (
            {new BigNumber(group.groupMerits).toFixed(2).toString()})
          </Typography>
        ),
        color: 'unset',
      };
    });

    return {
      children: groupsData,
    };
  };

  const handleMouseOut = e => {
    if (statsContainerRef && !statsContainerRef.current.contains(e.target)) {
      setTooltipVisible(false);
    }
  };

  useEffect(() => {
    if (statsContainerRef) {
      if (!containerLoaded) {
        setContainerLoaded(true);
      }
      document.addEventListener('mouseout', handleMouseOut);
    }
    return () => {
      document.removeEventListener('mouseout', handleMouseOut);
    };
  }, [statsContainerRef]);

  const handleLeafClick = leafNode => {
    if (!leafNode.data.participants) {
      setTooltipVisible(true);
      setTooltipData(leafNode.data);
      const positionLeft = leafNode.x0;
      const positionTop = leafNode.y1;
      const percentage = leafNode.value / (leafNode.parent.value / 100);
      setValuePercentage(new BigNumber(percentage).toFixed(2).toString());
      setTooltipPosition({
        x: positionLeft,
        y: positionTop,
      });
    }
  };

  return (
    <div
      ref={statsContainerRef}
      style={{
        boxSizing: 'border-box',
        paddingLeft: 10,
        paddingRight: 10,
        width: 1400,
        height: 1000,
        backgroundColor: '#fff',
        position: 'relative',
      }}
    >
      {containerLoaded && (
        <Treemap
          width={
            statsContainerRef.current.parentElement.getClientRects()[0].width
          }
          colorType="literal"
          colorRange={['#88572C']}
          height={1000}
          padding={4}
          data={normalizedGroups()}
          mode={'binary'}
          hideRootNode
          renderMode="DOM"
          onLeafMouseOver={handleLeafClick}
          getSize={d => d.merits || d.size}
          sortFunction={(a, b) => b.value - a.value}
        />
      )}
      {tooltipVisible && (
        <div
          className="tooltip-container"
          style={{
            top: tooltipPosition.y,
            left: tooltipPosition.x,
            opacity: tooltipVisible ? 1 : 0,
          }}
        >
          <Typography>
            {tooltipData.user.firstName} {tooltipData.user.lastName}:{' '}
            {`${new BigNumber(tooltipData.merits)
              .toFixed(2)
              .toString()} (${valuePercentage}%)`}
          </Typography>
        </div>
      )}
    </div>
  );
};

StatsComponent.propTypes = {
  data: PropTypes.object,
  showNames: PropTypes.bool,
  showPercents: PropTypes.bool,
  colors: PropTypes.array,
};

export default memo(StatsComponent);
