import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    // type:'dark',
    primary: { main: '#4D93E3', contrastText: 'white' },
    secondary: { main: '#F2BE16', contrastText: 'white' },
  },
});

export { theme };
